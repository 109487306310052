/* roboto-100normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Roboto Thin '),
    local('Roboto-Thin'),
    url(../fonts/roboto-latin-100.987b8457.woff2) format('woff2'), 
    url(../fonts/roboto-latin-100.e9dbbe8a.woff) format('woff'); /* Modern Browsers */
}

/* roboto-100italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src:
    local('Roboto Thin italic'),
    local('Roboto-Thinitalic'),
    url(../fonts/roboto-latin-100italic.6232f43d.woff2) format('woff2'), 
    url(../fonts/roboto-latin-100italic.d704bb3d.woff) format('woff'); /* Modern Browsers */
}

/* roboto-300normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Roboto Light '),
    local('Roboto-Light'),
    url(../fonts/roboto-latin-300.55536c8e.woff2) format('woff2'), 
    url(../fonts/roboto-latin-300.a1471d1d.woff) format('woff'); /* Modern Browsers */
}

/* roboto-300italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Roboto Light italic'),
    local('Roboto-Lightitalic'),
    url(../fonts/roboto-latin-300italic.d69924b9.woff2) format('woff2'), 
    url(../fonts/roboto-latin-300italic.210a7c78.woff) format('woff'); /* Modern Browsers */
}

/* roboto-400normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Roboto Regular '),
    local('Roboto-Regular'),
    url(../fonts/roboto-latin-400.5d4aeb4e.woff2) format('woff2'), 
    url(../fonts/roboto-latin-400.bafb105b.woff) format('woff'); /* Modern Browsers */
}

/* roboto-400italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Roboto Regular italic'),
    local('Roboto-Regularitalic'),
    url(../fonts/roboto-latin-400italic.d8bcbe72.woff2) format('woff2'), 
    url(../fonts/roboto-latin-400italic.9680d5a0.woff) format('woff'); /* Modern Browsers */
}

/* roboto-500normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Roboto Medium '),
    local('Roboto-Medium'),
    url(../fonts/roboto-latin-500.28546717.woff2) format('woff2'), 
    url(../fonts/roboto-latin-500.de8b7431.woff) format('woff'); /* Modern Browsers */
}

/* roboto-500italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Roboto Medium italic'),
    local('Roboto-Mediumitalic'),
    url(../fonts/roboto-latin-500italic.510dec37.woff2) format('woff2'), 
    url(../fonts/roboto-latin-500italic.ffcc050b.woff) format('woff'); /* Modern Browsers */
}

/* roboto-700normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Roboto Bold '),
    local('Roboto-Bold'),
    url(../fonts/roboto-latin-700.037d8304.woff2) format('woff2'), 
    url(../fonts/roboto-latin-700.cf6613d1.woff) format('woff'); /* Modern Browsers */
}

/* roboto-700italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Roboto Bold italic'),
    local('Roboto-Bolditalic'),
    url(../fonts/roboto-latin-700italic.010c1aee.woff2) format('woff2'), 
    url(../fonts/roboto-latin-700italic.846d1890.woff) format('woff'); /* Modern Browsers */
}

/* roboto-900normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Roboto Black '),
    local('Roboto-Black'),
    url(../fonts/roboto-latin-900.19b7a0ad.woff2) format('woff2'), 
    url(../fonts/roboto-latin-900.8c2ade50.woff) format('woff'); /* Modern Browsers */
}

/* roboto-900italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Roboto Black italic'),
    local('Roboto-Blackitalic'),
    url(../fonts/roboto-latin-900italic.7b770d6c.woff2) format('woff2'), 
    url(../fonts/roboto-latin-900italic.bc833e72.woff) format('woff'); /* Modern Browsers */
}


/* raleway-100normal - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Raleway Thin '),
    local('Raleway-Thin'),
    url(../fonts/raleway-latin-100.735afb75.woff2) format('woff2'), 
    url(../fonts/raleway-latin-100.d52dfbc3.woff) format('woff'); /* Modern Browsers */
}

/* raleway-100italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src:
    local('Raleway Thin italic'),
    local('Raleway-Thinitalic'),
    url(../fonts/raleway-latin-100italic.73f67602.woff2) format('woff2'), 
    url(../fonts/raleway-latin-100italic.b95c754a.woff) format('woff'); /* Modern Browsers */
}

/* raleway-200normal - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('Raleway Extra Light '),
    local('Raleway-Extra Light'),
    url(../fonts/raleway-latin-200.323561d9.woff2) format('woff2'), 
    url(../fonts/raleway-latin-200.47d350bd.woff) format('woff'); /* Modern Browsers */
}

/* raleway-200italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src:
    local('Raleway Extra Light italic'),
    local('Raleway-Extra Lightitalic'),
    url(../fonts/raleway-latin-200italic.76db40bf.woff2) format('woff2'), 
    url(../fonts/raleway-latin-200italic.a62e2b87.woff) format('woff'); /* Modern Browsers */
}

/* raleway-300normal - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Raleway Light '),
    local('Raleway-Light'),
    url(../fonts/raleway-latin-300.1acb42d7.woff2) format('woff2'), 
    url(../fonts/raleway-latin-300.34f26209.woff) format('woff'); /* Modern Browsers */
}

/* raleway-300italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Raleway Light italic'),
    local('Raleway-Lightitalic'),
    url(../fonts/raleway-latin-300italic.4380041f.woff2) format('woff2'), 
    url(../fonts/raleway-latin-300italic.180725ba.woff) format('woff'); /* Modern Browsers */
}

/* raleway-400normal - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Raleway Regular '),
    local('Raleway-Regular'),
    url(../fonts/raleway-latin-400.2075794c.woff2) format('woff2'), 
    url(../fonts/raleway-latin-400.bd67f25d.woff) format('woff'); /* Modern Browsers */
}

/* raleway-400italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Raleway Regular italic'),
    local('Raleway-Regularitalic'),
    url(../fonts/raleway-latin-400italic.de4bb8c8.woff2) format('woff2'), 
    url(../fonts/raleway-latin-400italic.a5888ae2.woff) format('woff'); /* Modern Browsers */
}

/* raleway-500normal - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Raleway Medium '),
    local('Raleway-Medium'),
    url(../fonts/raleway-latin-500.de818060.woff2) format('woff2'), 
    url(../fonts/raleway-latin-500.82718281.woff) format('woff'); /* Modern Browsers */
}

/* raleway-500italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Raleway Medium italic'),
    local('Raleway-Mediumitalic'),
    url(../fonts/raleway-latin-500italic.0ed855b4.woff2) format('woff2'), 
    url(../fonts/raleway-latin-500italic.5fbef688.woff) format('woff'); /* Modern Browsers */
}

/* raleway-600normal - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Raleway SemiBold '),
    local('Raleway-SemiBold'),
    url(../fonts/raleway-latin-600.bd2bb116.woff2) format('woff2'), 
    url(../fonts/raleway-latin-600.eae0742c.woff) format('woff'); /* Modern Browsers */
}

/* raleway-600italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Raleway SemiBold italic'),
    local('Raleway-SemiBolditalic'),
    url(../fonts/raleway-latin-600italic.c031cce6.woff2) format('woff2'), 
    url(../fonts/raleway-latin-600italic.43b080a4.woff) format('woff'); /* Modern Browsers */
}

/* raleway-700normal - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Raleway Bold '),
    local('Raleway-Bold'),
    url(../fonts/raleway-latin-700.dcbe8703.woff2) format('woff2'), 
    url(../fonts/raleway-latin-700.5098f8c8.woff) format('woff'); /* Modern Browsers */
}

/* raleway-700italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Raleway Bold italic'),
    local('Raleway-Bolditalic'),
    url(../fonts/raleway-latin-700italic.52a8c3f8.woff2) format('woff2'), 
    url(../fonts/raleway-latin-700italic.bfa3acd9.woff) format('woff'); /* Modern Browsers */
}

/* raleway-800normal - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Raleway ExtraBold '),
    local('Raleway-ExtraBold'),
    url(../fonts/raleway-latin-800.47609ca0.woff2) format('woff2'), 
    url(../fonts/raleway-latin-800.2277f1ba.woff) format('woff'); /* Modern Browsers */
}

/* raleway-800italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src:
    local('Raleway ExtraBold italic'),
    local('Raleway-ExtraBolditalic'),
    url(../fonts/raleway-latin-800italic.e571e4c8.woff2) format('woff2'), 
    url(../fonts/raleway-latin-800italic.b12796f5.woff) format('woff'); /* Modern Browsers */
}

/* raleway-900normal - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Raleway Black '),
    local('Raleway-Black'),
    url(../fonts/raleway-latin-900.0f85e4bf.woff2) format('woff2'), 
    url(../fonts/raleway-latin-900.a58f25dc.woff) format('woff'); /* Modern Browsers */
}

/* raleway-900italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Raleway Black italic'),
    local('Raleway-Blackitalic'),
    url(../fonts/raleway-latin-900italic.c8f1b2c3.woff2) format('woff2'), 
    url(../fonts/raleway-latin-900italic.f202fc5b.woff) format('woff'); /* Modern Browsers */
}


/* pacifico-400normal - latin */
@font-face {
  font-family: 'Pacifico';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Pacifico Regular '),
    local('Pacifico-Regular'),
    url(../fonts/pacifico-latin-400.ec020241.woff2) format('woff2'), 
    url(../fonts/pacifico-latin-400.b16407bb.woff) format('woff'); /* Modern Browsers */
}


/* pt-serif-400normal - latin */
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('PT Serif Regular '),
    local('PT Serif-Regular'),
    url(../fonts/pt-serif-latin-400.d099b509.woff2) format('woff2'), 
    url(../fonts/pt-serif-latin-400.f88b728c.woff) format('woff'); /* Modern Browsers */
}

/* pt-serif-400italic - latin */
@font-face {
  font-family: 'PT Serif';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('PT Serif Regular italic'),
    local('PT Serif-Regularitalic'),
    url(../fonts/pt-serif-latin-400italic.0c4aa22c.woff2) format('woff2'), 
    url(../fonts/pt-serif-latin-400italic.e080e39a.woff) format('woff'); /* Modern Browsers */
}

/* pt-serif-700normal - latin */
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('PT Serif Bold '),
    local('PT Serif-Bold'),
    url(../fonts/pt-serif-latin-700.49182afb.woff2) format('woff2'), 
    url(../fonts/pt-serif-latin-700.ee6644b6.woff) format('woff'); /* Modern Browsers */
}

/* pt-serif-700italic - latin */
@font-face {
  font-family: 'PT Serif';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('PT Serif Bold italic'),
    local('PT Serif-Bolditalic'),
    url(../fonts/pt-serif-latin-700italic.c7652c04.woff2) format('woff2'), 
    url(../fonts/pt-serif-latin-700italic.abcd0499.woff) format('woff'); /* Modern Browsers */
}



/*# sourceMappingURL=chunk-vendors.1c9b0c38.css.map*/